/** @define Results */

.Results {
  display: flex;
  flex-direction: column;
  color: palette(neutral, white);
}

.Results__header {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 8px 0;
  font-size: 0.8rem;

  @include lg {
    font-size: 0.8rem;
  }
}

.Results__link {
  cursor: pointer;
  justify-self: center;
  text-decoration: underline;
  font-size: 0.8rem;

  @include lg {
    font-size: 0.8rem;
  }
}

.Results__matchDay {
  justify-self: center;
}

.Results__month {
  &__matches {
    display: flex;
    flex-direction: column;
    background-color: palette(neutral, white);
    border-radius: $size-s + $size-xs;
    gap: $size-s;
  }

  &__title {
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: capitalize;
    margin: $size-m 0;
    padding: 0 $size-m;

    @include lg {
      padding: 0;
    }
  }
}

.Results__row {
  color: palette(neutral, black);
  font-family: themefont(), sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  // apply border radius on first row element and last row element
  &:first-child {
    border-top-left-radius: $size-s + $size-xs;
    border-top-right-radius: $size-s + $size-xs;
  }

  &:first-of-type {
    border-top-left-radius: $size-s + $size-xs;
    border-top-right-radius: $size-s + $size-xs;
  }

  &:last-child {
    border-bottom-left-radius: $size-s + $size-xs;
    border-bottom-right-radius: $size-s + $size-xs;
  }

  @include xs {
    font-size: 1rem;
  }

  @include lg {
    font-size: 1.4rem;
  }

  &__date {
    font-size: 0.8rem;
    position: absolute;

    @include lg {
      position: relative;
      width: 20%;
      display: flex;
      align-items: center;
      padding-left: $size-s + $size-xs;
    }

    &__container {
      height: 100%;
      width: $size-xl - $size-s;
      display: flex;
      flex-direction: column;
      margin-left: $size-s;

      @include lg {
        width: $size-xl + $size-m;
      }
    }

    &__day {
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: $size-xs;

      @include lg {
        font-size: 1.4rem;
      }
    }

    &__time {
      font-size: 0.8rem;
      text-align: center;

      @include lg {
        font-size: 1rem;
      }
    }
  }

  &__result {
    display: flex;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    align-items: center;
    justify-content: space-between;
    height: $size-xl;

    @include lg {
      width: 60%;
      max-width: 60%;
    }
  }

  &__team {
    display: flex;
    align-items: center;
    gap: $size-m;
    justify-content: flex-end;
    flex: 1;

    &_away {
      justify-content: flex-start;
    }
  }

  &__score {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    min-width: calc(#{$size-l} + #{$size-xs});
  }

  &__teamName {
    display: none;

    @include lg {
      display: inline-flex;
    }
  }

  &__teamName__abv {
    display: inline-flex;

    @include lg {
      display: none;
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    margin: $size-m 0;
  }
}

.Results__score {
  display: flex;
  font-size: 1rem;
  justify-content: center;
}

.Results__homeTeam,
.Results__awayTeam {
  width: 80%;
  align-self: center;

  @include lg {
    justify-self: center;
    width: auto;
  }
}

.Results__scoreItem_win {
  color: palette(neutral, green);
}

.Results__scoreItem_lose {
  color: palette(neutral, ladderalt);
}

.Results__scoreItem_draw {
  color: palette(neutral, neutral);
}
