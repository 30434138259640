/** @define App */

.App__content {
  min-height: calc((var(--vh, 1vh) * 100) - 75px);
  position: relative;
  margin-top: 75px;
  z-index: 1;
  height: auto;

  @include lg {
    // Full - Header
    min-height: calc((var(--vh, 1vh) * 100) - 100px);
    height: auto;
    margin-top: 100px;
  }
}

.App_noOverFlow {
  overflow: hidden;
}

.App__Footer {
  margin-top: $size-m;
  background-color: palette(neutral, red);
}
