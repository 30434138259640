/** @define MobileMenu */

.MobileMenu {
  background-color: palette(neutral, menu);
  font-family: themefont();
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 3;
  height: 60px;

  @include lg {
    display: none;
  }
}

.MobileMenu__Link {
  color: palette(neutral, white);
  text-decoration: none;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 700;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.MobileMenu__LinkContainer,
.MobileMenu__LinkContainerMore {
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  box-shadow: 1px 0 0 rgba(palette(neutral, white), 0.3);
  width: 20%;
}
