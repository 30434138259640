/* define Toggle */

.Toggle {
  display: flex;
  flex-direction: column;
  margin: $size-m;
  position: relative;
  z-index: 3;
}

.Toggle__Header {
  align-self: center;
  display: flex;
  gap: $size-m;
  margin: calc(#{$size-s} + #{$size-xs}) 0;
}

.Toggle__HeaderButton {
  background-color: palette(neutral, white);
  border: none;
  border-radius: 16px;
  color: palette(neutral, red);
  cursor: pointer;
  font-weight: bold;
  padding: calc(#{$size-s} + #{$size-xs}) $size-m;
  text-transform: uppercase;
  text-align: center;
  transition: all ease-in-out 0.5s;

  &:first-child {
    border: none;
  }

  &:hover {
    background-color: palette(neutral, red);
    color: palette(neutral, white);
  }
}

.Toggle__HeaderButton_Active {
  color: palette(neutral, white);
  background-color: palette(neutral, red);
  outline: none;
}

.Toggle__Content {
  margin: 0 $size-m;

  @include sm {
    margin: 0;
  }
}

.Toggle__ContentChildren {
  display: none;
  min-height: 80px;
  border-radius: $size-s + $size-xs;
}

.Toggle__ContentChildre_Show {
  display: block;
}

@media screen and (max-width: 768px) {
  .Toggle__HeaderButton {
    &:nth-child(n + 3) {
      display: none;
    }
  }
}
