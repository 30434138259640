/** @define Header */

.Header {
  background-color: palette(neutral, menu);
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 10;
}
