/** @define Menu */

.Menu {
  @include lg {
    align-items: center;
    display: flex;
    flex: 1 auto;
    background-color: palette(neutral, menu);
    font-family: themefont(), sans-serif;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 10;
    width: 100%;
  }
}

.Menu__Logo {
  position: fixed;
  top: 10px;
  height: 80px;
  width: 80px;
  left: calc(50% - 40px);
  z-index: 15;

  @include lg {
    position: relative;
    left: auto;
    top: auto;
  }
}
