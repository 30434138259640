/**
 * Will take a color from the "colors.scss" files
 *
 * eg.
 * a {
 *   color: palette(purple);
 *   &:hover {
 *     color: palette(purple, light);
 *   }
 * }
 */
@import './colors.scss';

@function palette($color, $tone: 'base') {
  @return map-get(map-get($color-theme, $color), $tone);
}
