@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Main {
  text-align: center;
  height: calc(var(--vh, 1vh) * 100 - 160px);
  z-index: 1;
  background-color: #d40915;
  margin-top: 100px;
  position: relative;
}

@media (min-width: 992px) {
  .Main {
    height: calc(var(--vh, 1vh) * 100 - 100px);
    margin-top: 100px;
  }
}

.Main__Slider {
  justify-content: center;
  display: flex;
}

.Main__Slide {
  height: 100%;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  justify-content: center;
  transition: all 2s ease-in-out;
  display: flex;
  position: absolute;
}

.Main__Slide_Show {
  opacity: 1;
  visibility: visible;
}

.Main__SliderTitle {
  color: #fff;
  font-family: Gabarito, sans-serif;
  font-size: .8rem;
  position: absolute;
  bottom: 92px;
  left: 50%;
  transform: translateX(-50%);
}

.Main__SliderImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.App__content {
  min-height: calc(var(--vh, 1vh) * 100 - 75px);
  z-index: 1;
  height: auto;
  margin-top: 75px;
  position: relative;
}

@media (min-width: 992px) {
  .App__content {
    min-height: calc(var(--vh, 1vh) * 100 - 100px);
    height: auto;
    margin-top: 100px;
  }
}

.App_noOverFlow {
  overflow: hidden;
}

.App__Footer {
  background-color: #d1041e;
  margin-top: 16px;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Header {
  height: 100px;
  width: 100%;
  z-index: 10;
  background-color: #ff3e3e;
  position: fixed;
  top: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

@media (min-width: 992px) {
  .Menu {
    height: 100%;
    z-index: 10;
    width: 100%;
    background-color: #ff3e3e;
    flex: auto;
    justify-content: center;
    align-items: center;
    font-family: Gabarito, sans-serif;
    display: flex;
    position: relative;
  }
}

.Menu__Logo {
  height: 80px;
  width: 80px;
  z-index: 15;
  position: fixed;
  top: 10px;
  left: calc(50% - 40px);
}

@media (min-width: 992px) {
  .Menu__Logo {
    position: relative;
    top: auto;
    left: auto;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.MobileMenu {
  width: 100%;
  z-index: 3;
  height: 60px;
  background-color: #ff3e3e;
  font-family: Gabarito;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (min-width: 992px) {
  .MobileMenu {
    display: none;
  }
}

.MobileMenu__Link {
  color: #fff;
  text-transform: uppercase;
  height: 100%;
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.MobileMenu__LinkContainer, .MobileMenu__LinkContainerMore {
  cursor: pointer;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 1px 0 #ffffff4d;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.MobileMenuAccordion {
  transform-origin: bottom;
  width: 100%;
  z-index: 3;
  background-color: #ff3e3e;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  flex-direction: column;
  transition: transform .5s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 60px;
  overflow: hidden;
  transform: scaleY(0);
}

@media (min-width: 992px) {
  .MobileMenuAccordion {
    display: none;
  }
}

.MobileMenuAccordion.is-open {
  transform-origin: bottom;
  transition: transform .5s ease-in-out;
  transform: scaleY(1);
}

.MobileMenuAccordion_open {
  height: auto;
  transform-origin: bottom;
  width: 100%;
  transition: transform .5s ease-in-out;
  display: flex;
  transform: scaleY(1);
}

@media (min-width: 992px) {
  .MobileMenuAccordion_open {
    display: none;
  }
}

.MobileMenuAccordion__Links {
  text-transform: uppercase;
  font-family: Gabarito;
  font-weight: bold;
  list-style: none;
}

.MobileMenuAccordion__Link {
  color: #fff;
  padding: 16px 8px;
  text-decoration: none;
  display: block;
}

@media (max-height: 700px) {
  .MobileMenuAccordion__Link {
    font-size: .7rem;
  }
}

.MobileMenuAccordion__LinkContainer {
  border-bottom: 1px solid #fff;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.MenuList {
  opacity: 1;
  width: calc(50% - 32px);
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 16px;
  font-family: Gabarito;
  list-style: none;
  display: none;
}

@media (min-width: 992px) {
  .MenuList {
    display: flex;
  }
}

.MenuList__Link {
  color: #fff;
  text-transform: uppercase;
  padding: 0 8px;
  text-decoration: none;
  display: none;
}

@media (min-width: 992px) {
  .MenuList__Link {
    font-size: .8rem;
    font-weight: 700;
    display: block;
  }
}

@media (min-width: 1200px) {
  .MenuList__Link {
    font-size: 1rem;
  }
}

.MenuList__Link:hover {
  color: #fff;
}

@media (min-width: 992px) {
  .MenuList__Link:hover {
    text-decoration: underline;
  }
}

.MenuList__PrimaryLink {
  cursor: pointer;
  width: 100%;
  justify-content: center;
  align-self: center;
  display: flex;
}

@media (min-width: 992px) {
  .MenuList__PrimaryLink {
    display: inherit;
    align-self: inherit;
    justify-content: inherit;
    width: inherit;
  }
}

.MenuList__LinkContainerPrimary {
  height: 80%;
  width: 25%;
  justify-content: space-between;
  display: flex;
  box-shadow: 1px 0 #ffffff4d;
}

@media (min-width: 992px) {
  .MenuList__LinkContainerPrimary {
    display: inherit;
    justify-content: inherit;
    box-shadow: none;
    height: auto;
    width: auto;
  }
}

.MenuList_alternate {
  justify-content: flex-start;
}

.MenuList__LinkContainerMore {
  display: flex;
}

@media (min-width: 992px) {
  .MenuList__LinkContainerMore {
    display: none;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Shop {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100 - 100px);
  justify-content: center;
  display: flex;
  position: relative;
}

.Shop__imageContainer {
  height: calc(var(--vh, 1vh) * 100 - 75px);
  width: 100%;
  background-color: #1515154d;
  position: fixed;
}

.Shop__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.Shop__overlay {
  height: calc((var(--vh, 1vh) * 100)  + 32px);
  z-index: 2;
  background-color: #0000004d;
  position: fixed;
  inset: 0 0 -40px;
}

@media (min-width: 992px) {
  .Shop__overlay {
    bottom: 0;
  }
}

.Shop__content {
  width: 80%;
  z-index: 2;
  color: #fff;
  margin-top: 32px;
  font-family: Gabarito, sans-serif;
  font-size: .9rem;
}

.Shop a {
  color: #fff;
  text-decoration: underline;
}

.Shop__products {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  width: 100%;
  z-index: 3;
  grid-template-columns: repeat(3, 1fr);
  margin: 32px;
  display: grid;
  position: relative;
}

@media (min-width: 1200px) {
  .Shop__products {
    width: 825px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 200px;
  }
}

.Shop__product {
  cursor: pointer;
  max-height: 450px;
  grid-area: span 1 / span 1;
  font-family: Gabarito;
  position: relative;
}

.Shop__productImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.Shop__bigProduct {
  grid-area: span 2 / span 3;
}

.Shop__productHover {
  opacity: 0;
  cursor: pointer;
  background-color: #000000e6;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .6rem;
  transition: all .8s ease-in-out;
  display: flex;
  position: absolute;
  inset: 0;
}

@media (min-width: 992px) {
  .Shop__productHover {
    font-size: .7rem;
  }
}

.Shop__productHover_display, .Shop__productHover:hover {
  opacity: 1;
}

.Shop__productButton {
  color: #10ac84;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #10ac84;
  border-radius: 50px;
  outline: none;
  margin-top: 8px;
  padding: 8px 12px 4px;
  font-family: Gabarito;
  transition: all .5s ease-in-out;
}

.Shop__productButton:hover {
  color: #fff;
  background-color: #10ac84;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.ShopItem {
  z-index: 2;
  width: 100%;
  margin: 32px;
  font-family: Gabarito;
  position: relative;
}

@media (min-width: 1200px) {
  .ShopItem {
    width: 50%;
  }
}

.ShopItem__back {
  align-self: flex-end;
  font-size: 1rem;
  font-weight: 700;
}

.ShopItem__content {
  color: #151515;
  background-color: #fff;
  border-radius: 24px;
  flex-direction: column;
  margin: 32px 0;
  padding: 36px;
  display: flex;
}

@media (min-width: 992px) {
  .ShopItem__content {
    flex-direction: row;
  }
}

.ShopItem__image {
  width: 60%;
  height: 100%;
  max-height: 450px;
  border-radius: 12px;
  align-self: center;
  margin-bottom: 32px;
}

@media (min-width: 1200px) {
  .ShopItem__image {
    width: 45%;
    align-self: inherit;
    margin-bottom: 0;
  }
}

.ShopItem__productImage {
  height: 100%;
  width: 100%;
  max-width: 450px;
  object-fit: cover;
  border-radius: 12px;
}

.ShopItem__product {
  width: auto;
  flex-direction: column;
  margin: 0 32px;
  display: flex;
}

@media (min-width: 992px) {
  .ShopItem__product {
    width: 70%;
  }
}

.ShopItem__productName, .ShopItem__productPrice {
  font-size: 1.2rem;
}

.ShopItem__productDescription {
  margin-top: 32px;
  font-size: .8rem;
}

.ShopItem__productButton {
  color: #fff;
  cursor: pointer;
  width: 100%;
  max-width: initial;
  background-color: #10ac84;
  border: 2px solid #10ac84;
  border-radius: 50px;
  outline: none;
  margin-top: 32px;
  padding: 8px 12px;
  font-family: Gabarito;
  transition: all .5s ease-in-out;
}

.ShopItem__productButton:hover {
  background-color: #13d7a6;
  border: 2px solid #13d7a6;
}

.ShopItem__select {
  margin-top: 32px;
}

.ShopItem__productDisclaimer {
  padding-top: 32px;
  font-size: .7rem;
}

.ShopItem__personalization {
  margin: 32px 0;
}

.ShopItem__personalizationInput {
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 16px 0;
  padding: 8px 16px;
  font-family: Gabarito;
}

.ShopItem__productInfo {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ShopItem__navigation {
  justify-content: space-between;
  margin-top: 32px;
  font-size: .7rem;
  display: flex;
}

.ShopItem__productSoldOut {
  margin: 32px 0;
  font-size: 1.3rem;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Season__imageContainer {
  height: calc(var(--vh, 1vh) * 100 - 75px);
  width: 100%;
  background-color: #1515154d;
  position: fixed;
}

.Season__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.Season__overlay {
  height: calc((var(--vh, 1vh) * 100)  + 32px);
  z-index: 2;
  background-color: #0000004d;
  position: fixed;
  inset: 0 0 -40px;
}

@media (min-width: 992px) {
  .Season__overlay {
    bottom: 0;
  }
}

.Season__content {
  color: #fff;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding-top: 16px;
  font-family: Gabarito, sans-serif;
  font-size: .9rem;
  display: flex;
  position: relative;
}

.Season__tabs {
  width: 100%;
}

@media (min-width: 992px) {
  .Season__tabs {
    width: 80%;
  }
}

.Season__link, .Season__link:visited, .Season__link:hover {
  color: #fff;
  z-index: 2;
  width: 80%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 64px;
  padding: 0 32px;
  font-family: Gabarito;
  text-decoration: none;
  display: flex;
  position: relative;
}

@media (min-width: 992px) {
  .Season__link, .Season__link:visited, .Season__link:hover {
    margin-bottom: 0;
  }
}

.Season__linkTitle {
  padding-right: 4px;
  font-size: .6rem;
}

.Season__imageTutto {
  width: 128px;
  background-color: #fffc;
  border-radius: 4px;
  padding: 4px;
}

.Season__links {
  width: 100%;
  justify-content: center;
  display: flex;
}

.Season__year {
  color: #d1041e;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  background-color: #fff;
  border: none;
  border-radius: 12px;
  padding: 8px 24px;
  font-weight: bold;
  transition: all .5s ease-in-out;
}

.Season__year:first-child {
  border: none;
}

.Season__year:hover {
  color: #fff;
  background-color: #d1041e;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Results {
  color: #fff;
  flex-direction: column;
  display: flex;
}

.Results__header {
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin: 8px 0;
  font-size: .8rem;
  display: grid;
}

@media (min-width: 992px) {
  .Results__header {
    font-size: .8rem;
  }
}

.Results__link {
  cursor: pointer;
  justify-self: center;
  font-size: .8rem;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .Results__link {
    font-size: .8rem;
  }
}

.Results__matchDay {
  justify-self: center;
}

.Results__month__matches {
  background-color: #fff;
  border-radius: 12px;
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.Results__month__title {
  text-transform: capitalize;
  margin: 16px 0;
  padding: 0 16px;
  font-size: 1.2rem;
  font-weight: bold;
}

@media (min-width: 992px) {
  .Results__month__title {
    padding: 0;
  }
}

.Results__row {
  color: #151515;
  width: 100%;
  align-items: center;
  font-family: Gabarito, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  position: relative;
}

.Results__row:first-child, .Results__row:first-of-type {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.Results__row:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

@media (max-width: 400px) {
  .Results__row {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .Results__row {
    font-size: 1.4rem;
  }
}

.Results__row__date {
  font-size: .8rem;
  position: absolute;
}

@media (min-width: 992px) {
  .Results__row__date {
    width: 20%;
    align-items: center;
    padding-left: 12px;
    display: flex;
    position: relative;
  }
}

.Results__row__date__container {
  height: 100%;
  width: 56px;
  flex-direction: column;
  margin-left: 8px;
  display: flex;
}

@media (min-width: 992px) {
  .Results__row__date__container {
    width: 80px;
  }
}

.Results__row__date__day {
  text-align: center;
  margin-bottom: 4px;
  font-size: 1rem;
  font-weight: bold;
}

@media (min-width: 992px) {
  .Results__row__date__day {
    font-size: 1.4rem;
  }
}

.Results__row__date__time {
  text-align: center;
  font-size: .8rem;
}

@media (min-width: 992px) {
  .Results__row__date__time {
    font-size: 1rem;
  }
}

.Results__row__result {
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 992px) {
  .Results__row__result {
    width: 60%;
    max-width: 60%;
  }
}

.Results__row__team {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  display: flex;
}

.Results__row__team_away {
  justify-content: flex-start;
}

.Results__row__score {
  width: 10%;
  min-width: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Results__row__teamName {
  display: none;
}

@media (min-width: 992px) {
  .Results__row__teamName {
    display: inline-flex;
  }
}

.Results__row__teamName__abv {
  display: inline-flex;
}

@media (min-width: 992px) {
  .Results__row__teamName__abv {
    display: none;
  }
}

.Results__row__title {
  text-align: center;
  margin: 16px 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.Results__score {
  justify-content: center;
  font-size: 1rem;
  display: flex;
}

.Results__homeTeam, .Results__awayTeam {
  width: 80%;
  align-self: center;
}

@media (min-width: 992px) {
  .Results__homeTeam, .Results__awayTeam {
    width: auto;
    justify-self: center;
  }
}

.Results__scoreItem_win {
  color: #10ac84;
}

.Results__scoreItem_lose {
  color: #e66666;
}

.Results__scoreItem_draw {
  color: #aaa;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Ladder {
  color: #fff;
  grid-auto-rows: 48px;
  font-family: Gabarito, sans-serif;
  font-weight: bold;
  display: grid;
}

.Ladder__stylised {
  font-family: Gabarito, sans-serif;
  font-weight: bold;
}

.Ladder__header .Ladder__item {
  cursor: pointer;
}

.Ladder__teamName {
  text-align: center;
  font-size: 1rem;
}

@media (max-width: 400px) {
  .Ladder__teamName {
    font-size: .8rem;
  }
}

.Ladder__row {
  color: #151515;
  background-color: #fff;
  border-top: none;
  grid-template-columns: 48px 1fr repeat(4, 56px);
  padding: 16px 0;
  font-size: 1rem;
  display: grid;
}

@media (min-width: 992px) {
  .Ladder__row {
    grid-template-columns: 48px 1fr repeat(7, 64px);
  }
}

.Ladder__row:nth-child(2n+1) {
  background-color: #fff;
}

.Ladder__row_velasca {
  color: #fff;
  background-color: #d40915 !important;
}

.Ladder__row:first-child {
  background-color: #ff3e3e;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

@media (max-width: 400px) {
  .Ladder__row {
    font-size: .8rem;
  }
}

.Ladder__item {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Ladder__item:nth-child(n+7) {
  display: none;
}

@media (min-width: 992px) {
  .Ladder__item:nth-child(n+7) {
    display: flex;
  }
}

.Ladder__item_alt {
  font-weight: bold;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Toggle {
  z-index: 3;
  flex-direction: column;
  margin: 16px;
  display: flex;
  position: relative;
}

.Toggle__Header {
  align-self: center;
  gap: 16px;
  margin: 12px 0;
  display: flex;
}

.Toggle__HeaderButton {
  color: #d1041e;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  background-color: #fff;
  border: none;
  border-radius: 16px;
  padding: 12px 16px;
  font-weight: bold;
  transition: all .5s ease-in-out;
}

.Toggle__HeaderButton:first-child {
  border: none;
}

.Toggle__HeaderButton:hover {
  color: #fff;
  background-color: #d1041e;
}

.Toggle__HeaderButton_Active {
  color: #fff;
  background-color: #d1041e;
  outline: none;
}

.Toggle__Content {
  margin: 0 16px;
}

@media (max-width: 576px) {
  .Toggle__Content {
    margin: 0;
  }
}

.Toggle__ContentChildren {
  min-height: 80px;
  border-radius: 12px;
  display: none;
}

.Toggle__ContentChildre_Show {
  display: block;
}

@media screen and (max-width: 768px) {
  .Toggle__HeaderButton:nth-child(n+3) {
    display: none;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Edito {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100 - 100px);
  justify-content: center;
  margin: 100px 0;
  display: flex;
  position: relative;
}

@media (min-width: 992px) {
  .Edito {
    margin: 100px 0 0;
  }
}

.Edito__imageContainer {
  height: calc(var(--vh, 1vh) * 100 - 75px);
  width: 100%;
  background-color: #1515154d;
  position: fixed;
}

.Edito__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.Edito__overlay {
  height: calc((var(--vh, 1vh) * 100)  + 32px);
  z-index: 2;
  background-color: #0000004d;
  position: fixed;
  inset: 0 0 -40px;
}

@media (min-width: 992px) {
  .Edito__overlay {
    bottom: 0;
  }
}

.Edito__content {
  width: 100%;
  z-index: 2;
  color: #151515;
  background-color: #fff;
  border-radius: 24px;
  margin-top: 32px;
  padding: 32px;
  font-family: Gabarito;
  font-size: 1rem;
}

@media (min-width: 992px) {
  .Edito__content {
    width: 80%;
    margin-top: 64px;
  }
}

.Edito__content img {
  filter: drop-shadow(2px 4px 6px #000);
  border-radius: 12px;
}

.Edito a {
  color: #151515;
  font-weight: 700;
  text-decoration: underline;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Errors {
  color: #fff;
  height: calc(var(--vh, 1vh) * 100 - 100px);
  background-color: #d1041e;
  flex-direction: column;
  align-items: center;
  padding: 64px;
  font-family: Gabarito, sans-serif;
  display: flex;
  overflow: hidden;
}

.Errors__title {
  font-size: 3rem;
  font-weight: bold;
}

.Errors__code {
  font-size: 2rem;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Disclaimer {
  min-height: calc(var(--vh, 1vh) * 100);
  background: #d1041e;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.Disclaimer__Image {
  width: 300px;
}

@media (min-width: 992px) {
  .Disclaimer__Image {
    width: 380px;
  }
}

.Disclaimer__Sponsors {
  flex-direction: column;
  display: flex;
}

.Disclaimer__LangSelector {
  flex-direction: row;
  margin-top: 8px;
  display: flex;
}

.Disclaimer__Sponsor {
  height: 80px;
  width: 80px;
}

.Disclaimer__Link {
  color: #fff;
  text-transform: capitalize;
  padding-left: 2px;
  font-family: Gabarito;
  font-size: 1rem;
  font-weight: 800;
  text-decoration: none;
}

.Disclaimer__link_alt {
  padding-top: 16px;
}

.Disclaimer__SponsorContainer {
  margin: 4px;
}

.Disclaimer__Enter {
  color: #fff;
  border: 4px solid #fff;
  padding: 8px 12px;
  font-family: Gabarito;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  transition: all .5s ease-in-out;
}

@media (min-width: 992px) {
  .Disclaimer__Enter {
    padding: 8px 12px;
    font-size: 2rem;
  }
}

.Disclaimer__Enter:hover {
  color: #aaa;
  border: 4px solid #aaa;
}

.Disclaimer__Social {
  width: 50%;
  margin-top: 32px;
  position: relative;
}

.Disclaimer__Social .Social {
  justify-content: center;
  position: relative;
  bottom: auto;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.LangSelector {
  width: 100%;
  z-index: 10;
  flex-direction: row;
  justify-content: flex-end;
  font-family: Gabarito, sans-serif;
  font-size: .8rem;
  display: flex;
  position: absolute;
  bottom: 0;
}

.LangSelector__item {
  padding: 0 8px;
}

.LangSelector__link {
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  text-decoration: none;
}

.LangSelector__link:hover {
  text-decoration: underline;
}

.LangSelector__link_current {
  color: #151515;
  cursor: pointer;
  pointer-events: none;
}

.LangSelector__link_current:hover {
  text-decoration: underline;
}

*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  background-color: palette(neutral, menu);
  line-height: 1.5;
  overflow-x: hidden;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font-size: 100%;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

h6 {
  color: #fff;
  width: 150px;
  background-color: #d1041e;
  border-radius: 8px;
  padding: 8px;
  font-family: Gabarito;
  font-size: .8rem;
  position: relative;
}

h3 {
  font-weight: bold;
}

table {
  color: #fff;
}

select, button {
  max-width: 300px;
}

.Shop {
  color: #fff;
  width: 100%;
  z-index: 3;
  margin: 100px 0;
  position: relative;
}

@media (min-width: 992px) {
  .Shop {
    margin: 100px 0 0;
  }
}

.Shop__Items {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Shop__Items {
    flex-direction: row;
  }
}

.Shop__Item {
  flex: 100%;
  margin: 32px 0;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .Shop__Item {
    flex: 50%;
    margin: 32px;
  }
}

@media (min-width: 1200px) {
  .Shop__Item {
    flex: 33%;
  }
}

.Shop__Item h3 {
  font-family: Gabarito;
  font-size: 1rem;
}

.Shop__ItemImage {
  height: auto;
  max-height: 200px;
}

@media (min-width: 992px) {
  .Shop__ItemImage {
    height: auto;
    max-height: 300px;
  }
}

.Shop__Form {
  flex-direction: column;
  display: flex;
}

.Shop__ItemDescription {
  width: 55%;
  padding: 16px;
}

.Shop__Button {
  cursor: pointer;
  color: #fff;
  background-color: #10ac84;
  border: none;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 32px;
  font-family: Gabarito;
  font-size: 1rem;
  transition: all .5s ease-in-out;
}

.Shop__Button:hover {
  background-color: #10ac84b3;
}

.ResultContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  display: flex;
}

.Results, .Ladder {
  width: 100%;
}

.Social {
  z-index: 10;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  position: absolute;
  bottom: 8px;
}

.Social__Item {
  padding: 0 4px;
}

/*# sourceMappingURL=index.b60789f3.css.map */
