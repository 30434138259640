/** @define Social */

.Social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  bottom: $size-s;
  z-index: 10;
}

.Social__Item {
  padding: 0 $size-xs;
}
