/** @define ShopItem */

.ShopItem {
  z-index: 2;
  position: relative;
  margin: $size-l;
  font-family: themefont();
  width: 100%;

  @include xl {
    width: 50%;
  }
}

.ShopItem__back {
  font-size: 1rem;
  font-weight: 700;
  align-self: flex-end;
}

.ShopItem__content {
  display: flex;
  flex-direction: column;
  margin: $size-l 0;
  background-color: palette(neutral, white);
  border-radius: 24px;
  color: palette(neutral, black);
  padding: 36px;

  @include lg {
    flex-direction: row;
  }
}

.ShopItem__image {
  width: 60%;
  height: 100%;
  max-height: 450px;
  margin-bottom: $size-l;
  align-self: center;
  border-radius: 12px;

  @include xl {
    width: 45%;
    margin-bottom: 0;
    align-self: inherit;
  }
}

.ShopItem__productImage {
  height: 100%;
  width: 100%;
  max-width: 450px;
  object-fit: cover;
  border-radius: 12px;
}

.ShopItem__product {
  display: flex;
  flex-direction: column;
  margin: 0 $size-l;
  width: auto;

  @include lg {
    width: 70%;
  }
}

.ShopItem__productName,
.ShopItem__productPrice {
  font-size: 1.2rem;
}

.ShopItem__productDescription {
  font-size: 0.8rem;
  margin-top: $size-l;
}

.ShopItem__productButton {
  border: 2px solid palette(neutral, green);
  background-color: palette(neutral, green);
  padding: 8px 12px;
  color: palette(neutral, white);
  font-family: themefont();
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.5s ease-in-out;
  margin-top: $size-l;
  width: 100%;
  max-width: initial;

  &:hover {
    background-color: palette(neutral, greenHover);
    border: 2px solid palette(neutral, greenHover);
  }
}

.ShopItem__select {
  margin-top: $size-l;
}

.ShopItem__productDisclaimer {
  font-size: 0.7rem;
  padding-top: $size-l;
}

.ShopItem__personalization {
  margin: $size-l 0;
}

.ShopItem__personalizationInput {
  border: 1px palette(neutral, neutral) solid;
  font-family: themefont();
  padding: $size-s $size-m;
  border-radius: 4px;
  margin: $size-m 0;
}

.ShopItem__productInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ShopItem__navigation {
  display: flex;
  justify-content: space-between;
  margin-top: $size-l;
  font-size: 0.7rem;
}

.ShopItem__productSoldOut {
  margin: $size-l 0;
  font-size: 1.3rem;
}
