/** @define Edito */

.Edito {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 100px 0;
  min-height: calc((var(--vh, 1vh) * 100) - 100px);

  @include lg {
    margin: 100px 0 0;
  }
}

.Edito__imageContainer {
  position: fixed;
  height: calc((var(--vh, 1vh) * 100) - 75px);
  width: 100%;
  background-color: rgba(palette(neutral, black), 0.3);
}

.Edito__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.Edito__overlay {
  top: 0;
  bottom: -40px;
  left: 0;
  right: 0;
  height: calc((var(--vh, 1vh) * 100) + #{$size-l});
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  position: fixed;

  @include lg {
    bottom: 0;
  }
}

.Edito__content {
  width: 100%;
  z-index: 2;
  color: palette(neutral, black);
  font-family: themefont();
  font-size: 1rem;
  margin-top: $size-l;
  background-color: palette(neutral, white);
  padding: 32px;
  border-radius: 24px;

  @include lg {
    width: 80%;
    margin-top: $size-xl;
  }

  & img {
    filter: drop-shadow(2px 4px 6px black);
    border-radius: 12px;
  }
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.Edito a {
  text-decoration: underline;
  font-weight: 700;
  color: palette(neutral, black);
}
