/** @define Errors */

.Errors {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: themefont(), sans-serif;
  color: palette(neutral, white);
  padding: $size-xl;
  height: calc((var(--vh, 1vh) * 100) - 100px);
  overflow: hidden;
  background-color: palette(neutral, red);
}

.Errors__title {
  font-size: 3rem;
  font-weight: bold;
}

.Errors__code {
  font-size: 2rem;
}
