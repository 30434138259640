/** @define Main */

.Main {
  background-color: palette(neutral, redhome);
  text-align: center;
  // Full - Header - Footer
  height: calc((var(--vh, 1vh) * 100) - 160px);
  position: relative;
  margin-top: 100px;
  z-index: 1;

  @include lg {
    // Full - Header
    height: calc((var(--vh, 1vh) * 100) - 100px);
    margin-top: 100px;
  }
}

.Main__Slider {
  display: flex;
  justify-content: center;
}

.Main__Slide {
  justify-content: center;
  height: 100%;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 100%;
  display: flex;
  transition: all 2s ease-in-out;
}

.Main__Slide_Show {
  opacity: 1;
  visibility: visible;
}

.Main__SliderTitle {
  color: palette(neutral, white);
  font-family: themefont(), sans-serif;
  font-size: 0.8rem;
  position: absolute;
  bottom: 60px + $size-l;
  left: 50%;
  transform: translateX(-50%);
}

.Main__SliderImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
