/** @define Ladder */

.Ladder {
  display: grid;
  grid-auto-rows: $size-l + $size-m;
  color: palette(neutral, white);
  font-family: themefont(), sans-serif;
  font-weight: bold;
}

.Ladder__stylised {
  font-family: themefont(), sans-serif;
  font-weight: bold;
}

.Ladder__header {
  .Ladder__item {
    cursor: pointer;
  }
}

.Ladder__teamName {
  text-align: center;
  font-size: 1rem;

  @include xs {
    font-size: 0.8rem;
  }
}

.Ladder__row {
  background-color: palette(neutral, white);
  color: palette(neutral, black);
  border-top: none;
  display: grid;
  grid-template-columns: calc(#{$size-l} + #{$size-m}) 1fr repeat(4, 56px);
  font-size: 1rem;
  padding: $size-m 0;

  @include lg {
    grid-template-columns: calc(#{$size-l} + #{$size-m}) 1fr repeat(7, $size-xl);
  }

  &:nth-child(odd) {
    background-color: palette(neutral, white);
  }

  &_velasca {
    background-color: palette(neutral, redhome) !important;
    color: palette(neutral, white);
  }

  &:first-child {
    background-color: palette(neutral, menu);
    border-top-left-radius: $size-s + $size-xs;
    border-top-right-radius: $size-s + $size-xs;
  }

  @include xs {
    font-size: 0.8rem;
  }
}

.Ladder__item {
  display: flex;
  justify-content: center;
  align-items: center;

  &:nth-child(n + 7) {
    display: none;
  }

  @include lg {
    &:nth-child(n + 7) {
      display: flex;
    }
  }
}

.Ladder__item_alt {
  font-weight: bold;
}
