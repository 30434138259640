/* stylelint-disable color-no-hex */

$color-theme: (
  neutral: (
    red: #d1041e,
    redhome: #d40915,
    menu: #ff3e3e,
    ladderalt: #e66666,
    white: #fff,
    light: #eee,
    neutral: #aaa,
    black: #151515,
    blue: #345f90,
    green: #10ac84,
    greenHover: #13d7a6,
  ),
);
