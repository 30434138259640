/** @define MenuList */

.MenuList {
  display: none;
  opacity: 1;
  flex-direction: row;
  font-family: themefont();
  justify-content: flex-end;
  padding: 0 16px;
  width: calc(50% - #{$size-l});
  list-style: none;

  @include lg {
    display: flex;
  }
}

.MenuList__Link {
  color: palette(neutral, white);
  padding: 0 $size-s;
  text-decoration: none;
  text-transform: uppercase;
  display: none;

  @include lg {
    display: block;
    font-weight: 700;
    font-size: 0.8rem;
  }

  @include xl {
    font-size: 1rem;
  }

  &:hover {
    color: palette(neutral, white);

    @include lg {
      text-decoration: underline;
    }
  }
}

.MenuList__PrimaryLink {
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;

  @include lg {
    display: inherit;
    align-self: inherit;
    justify-content: inherit;
    width: inherit;
  }
}

.MenuList__LinkContainerPrimary {
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 0 0 rgba(palette(neutral, white), 0.3);
  height: 80%;
  width: 25%;

  @include lg {
    display: inherit;
    justify-content: inherit;
    box-shadow: none;
    height: auto;
    width: auto;
  }
}

.MenuList_alternate {
  justify-content: flex-start;
}

.MenuList__LinkContainerMore {
  display: flex;

  @include lg {
    display: none;
  }
}
