/** @define Disclaimer */

.Disclaimer {
  background: palette(neutral, red);
  min-height: calc((var(--vh, 1vh) * 100));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
}

.Disclaimer__Image {
  width: 300px;

  @include lg {
    width: 380px;
  }
}

.Disclaimer__Sponsors {
  display: flex;
  flex-direction: column;
}

.Disclaimer__LangSelector {
  display: flex;
  flex-direction: row;
  margin-top: $size-s;
}

.Disclaimer__Sponsor {
  height: 80px;
  width: 80px;
}

.Disclaimer__Link {
  font-family: themefont();
  color: palette(neutral, white);
  text-transform: capitalize;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding-left: 2px;
}

.Disclaimer__link_alt {
  padding-top: $size-m;
}

.Disclaimer__SponsorContainer {
  margin: $size-xs;
}

.Disclaimer__Enter {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: themefont();
  color: palette(neutral, white);
  border: $size-xs solid palette(neutral, white);
  padding: $size-s calc(#{$size-s} + #{$size-xs});
  text-decoration: none;
  transition: all ease-in-out 0.5s;

  @include lg {
    font-size: 2rem;
    padding: $size-s calc(#{$size-s} + #{$size-xs});
  }

  &:hover {
    color: palette(neutral, neutral);
    border: $size-xs solid palette(neutral, neutral);
  }
}

.Disclaimer__Social {
  position: relative;
  margin-top: $size-l;
  width: 50%;

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .Social {
    justify-content: center;
    position: relative;
    bottom: auto;
  }
}
